const SocialEndpoints = {
  linkedin: {
    isConnected: "/social/linkedin/isconnected",
    connect: "/social/linkedin/connect",
    disconnect: "/social/linkedin/disconnect",
  },
  posts: {
    index: "social/post/",
    drafts: "social/post/drafts",
    new: "social/post/new",
    edit: "social/post/edit",
    delete: "social/post/delete/",
    show: "social/post/show",
    setDraft: "social/post/setDraft/",
    schedule: {
      new: "social/post/schedule/new",
      edit: "social/post/schedule/edit",
      delete: "social/post/schedule/delete/"
    },
    publish: {
      linkedin: "social/linkedin/publish/",
    },
    postImage: "social/post/postimage/",
    ai:{
      finishPost: "ai/content/finishPost/",
    },
    dataField: "posts",
  },
};

export default SocialEndpoints;
