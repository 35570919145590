import { Routes, Route } from "react-router-dom";
import Landingpage from "../frontend/Landingpage";
import NotFoundPage from "../shared/404";
import Dashboard from "./Dashboard";
import AdminDashboard from "./AdminDashboard";
import BackendLayout from "./BackendLayout";
import Callbacks from "./Callbacks";

import LoggingIn from "./LogingIn";
import { Container } from "react-bootstrap";
import Timeline from "./Timeline";

const BackendRouter = () => {
  return (
    <Container fluid>
      <Routes>
        <Route element={<BackendLayout />}>
          <Route path="/" element={<AdminDashboard />} />
          <Route path="/callbacks/*" element={<Callbacks />} />
          <Route path="/login" element={<LoggingIn />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Container>
  );
};

export default BackendRouter;
