import { Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Sidebar from "./Sidebar";
import { Navbar } from "react-bootstrap";
import { useState, useEffect } from "react";


const BackendLayout = ({token, setToken}) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();

  useEffect(() => {
      setShowSidebar(false);
  }, [location])


  return (
    <>
      <Navbar bg="light" expand="lg" className="flex-column">
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setShowSidebar(!showSidebar)}/>
      </Navbar>
      <Sidebar showSidebar={showSidebar} />
      <div className="main-content">
        <div className="page-content">
          <Container fluid>
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
};

export default BackendLayout;
