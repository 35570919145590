import { Row, Col } from "react-bootstrap";

const Dashboard = () => {
  return (
    <Row>
      <Col md={6}></Col>
      <Col></Col>
    </Row>
  );
};

export default Dashboard;
