import PayModal from "../Modals/PayModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const NewButtonWithIcon = ({
  title = null,
  showPayModal,
  setShowPayModal,
  handleClick,
  color = "success",
}) => {
  const { t } = useTranslation();

  title = title ? title : t("Backend.Buttons.New");
  return (
    <>
      <PayModal show={showPayModal} setShow={setShowPayModal} />
      <button
        type="button"
        className={`btn btn-${color} hstack gap-2`}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <FontAwesomeIcon icon={faAdd} />
        <span className="vr"></span>
        {title}
      </button>
    </>
  );
};

export default NewButtonWithIcon;
