import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const localId = localStorage.getItem("localId");
    const userToken = JSON.parse(tokenString);
    const uuid = JSON.parse(localId);
    return userToken;
  };

  const getRoles = () => {
    const roles = localStorage.getItem("roles");
    if (typeof roles !== "undefined") {
      return JSON.parse(roles);
    }
    return [];
  };

  const [token, setToken] = useState(getToken());
  const [roles, setRoles] = useState(getRoles());

  const saveToken = (userToken, localId, roles = []) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    localStorage.setItem("localId", JSON.stringify(localId));
    localStorage.setItem("roles", JSON.stringify(roles));
    localStorage.setItem("loginTime", String(Date.now()));
    setToken(userToken);
  };

  if (
    token &&
    Math.abs(Date.now() - Number(localStorage.getItem("loginTime"))) /
      60000 >=
      59
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
    window.location.replace("/");
  }

  const logout = () => {
    // Remove items from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("localId");
    localStorage.removeItem("loginTime");
    localStorage.removeItem("roles");
    // Update the state to reflect the logout
    setToken(null);
    // Redirect to login page or root, based on your application's flow
    window.location.href = "/login"; // Change '/login' to your login route if it's different
  };

  return {
    setToken: saveToken,
    token,
    logout,
    roles,
  };
}
