import { Row, Col } from "react-bootstrap";
import Timelineitem from "./components/Timelineitem";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import listHandler from "@serie3/common/Domain/listHandler";
import PostFormView from "./components/PostFormView";
import AddButton from "../shared/Buttons/AddButton";
import ToastMessages from "../../ToastMessages";
import { useTranslation } from "react-i18next";

const Timeline = () => {
  const [posts, setPosts] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editPost, setEditPost] = useState();
  const [trigger, setTrigger] = useState(-1);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.index,
        setPosts,
        SocialEndpoints.posts.dataField
      );
    };
    const fetchDrafts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.drafts,
        setDrafts,
        SocialEndpoints.posts.dataField
      );
    };

    fetchPosts();
    fetchDrafts();
  }, [trigger]);

  const editAction = (post) => {
    setEditPost(post);
    setShowEdit(true);
  };

  const postErrorFunction = () => {
    let message = t("Backend.Post.ErrorPublishing");
    ToastMessages.Error(message);
  };

  const AddBtn = () => {
    return (
      <AddButton
        addFunction={async () => {
          let response = await crudApi.createNew(SocialEndpoints.posts.new);
          if (response.ok) {
            listHandler.newToList(response, posts, setPosts, null, true);
            setEditPost(response.data);
            setShowEdit(true);
          }
        }}
      />
    );
  };

  const deleteAction = (updateListFunction, dataList, index, postId) => {
    const deleteUrl = SocialEndpoints.posts.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, postId),
      updateListFunction,
      dataList
    );
  };

  const publish = async (postId) => {
    const response = await crudApi.justGet(
      SocialEndpoints.posts.publish.linkedin + postId
    );
    let data = JSON.stringify(response.data);
    listHandler.updateList(
      response,
      data,
      posts,
      setPosts,
      postErrorFunction,
      true
    );
    if (response.ok) {
      let message = t("Backend.Post.SuccessfulPublished");
      ToastMessages.SuccessfulSave(message);
    }
  };

  const setDraft = async (postId) => {
    const response = await crudApi.justGet(
      SocialEndpoints.posts.setDraft + postId
    );
    let data = JSON.stringify(response.data);
    listHandler.updateList(
      response,
      data,
      posts,
      setPosts,
      postErrorFunction,
      true
    );
    if (response.ok) {
      let message = t("Backend.Post.SuccessfulPublished");
      ToastMessages.SuccessfulSave(message);
    }
  };

  return (
    <>
      {editPost && (
        <PostFormView
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          dataList={posts}
          setDataList={setPosts}
          editData={editPost}
          setEditData={setEditPost}
          setTrigger={setTrigger}
          trigger={trigger}
        />
      )}
      <Row>
        <Col>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Timeline</h4>
            <AddBtn />
          </div>
        </Col>
      </Row>
      <div className="verti-timeline left-timeline">
        <Row>
          <Col>
            {drafts.map((draft, index) => {
              return (
                <Timelineitem
                  key={draft.id}
                  post={draft}
                  publish={publish}
                  editAction={() => {
                    editAction(draft);
                  }}
                  deleteAction={() => {
                    deleteAction(setDrafts, drafts, index, draft.id);
                  }}
                />
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            {posts.map((post, index) => {
              return (
                <Timelineitem
                  key={post.id}
                  post={post}
                  publish={publish}
                  editAction={() => {
                    editAction(post);
                  }}
                  deleteAction={() => {
                    deleteAction(setPosts, posts, index, post.id);
                  }}
                  setDraftAction={() => {
                    setDraft(post.id);
                  }}
                />
              );
            })}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Timeline;
