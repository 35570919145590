import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  faLink,
  faRightFromBracket,
  faTimeline,
  faGauge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Brand from "../shared/Brand";
import { getAuth, signOut } from "firebase/auth";
import useToken from "../../security/useToken";
import firebaseConfig from "@serie3/mymediaplanner/Config/Firebase/firebase";
import { initializeApp } from "firebase/app";
import { useTranslation } from "react-i18next";
import Timer from "./Timer";

const Sidebar = ({ showSidebar = false, setShowSidebar}) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const {t} = useTranslation();
  return (
    <>
      <div
        className="vertical-menu"
        style={{ display: showSidebar ? "block" : "" }}
      >
        <div
          className="navbar-brand-box"
          style={{ height: "70px", paddingTop: "20px" }}
        >
          <Brand />
        </div>
        <div className="h-100">
          <div className="sidebar-menu-scroll">
            <div className="simplebar-mask" style={{ marginTop: "70px" }}>
              <div id="sidebar-menu">
                <ul className="metismenu list-unstyled">
                  <li className="menu-title">Navi</li>
                  <li>
                    <Link to="/">
                      <FontAwesomeIcon icon={faGauge} />
                      <span className="menu-item">Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/timeline">
                      <FontAwesomeIcon icon={faTimeline} />
                      <span className="menu-item">Timeline</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/callbacks/linkedin">
                      <FontAwesomeIcon icon={faLink} />
                      <span className="menu-item">Connections</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => {
                        setToken(null, null);
                        window.location.reload();
                        signOut(auth);
                        navigate("/");
                      }}
                    >
                      <FontAwesomeIcon icon={faRightFromBracket} />
                      <span className="menu-item">Logout</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mainnav__bottom-content border-top pb-2">
          <ul id="mainnav" className="mainnav__menu nav flex-column">
            <li className="nav-item">
              <div className="mininav-toggle">
                <i className="pli-unlock fs-5 me-2"></i>
                <a
                  href="#"
                  className="nav-link mininav-content ms-1"
                  onClick={() => {
                    setToken(null, null);
                    window.location.reload();
                    signOut(auth);
                  }}
                >
                  {t("Backend.Navigation.Logout")}
                </a>
                <a
                  href="#"
                  className="nav-link mininav-content ms-4"
                  style={{ fontSize: "smaller" }}
                  onClick={() => {}}
                >
                  <Timer small={true} showLoginForm={showLoginForm} setShowLoginForm={setShowLoginForm}/>
                </a>
              </div>
            </li>
          </ul>
        </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
