import PostForm from "@serie3/mymediaplanner/Modules/Social/Forms/PostForm";
import { useFormik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import TextField from "../../Form/TextField";
import TextArea from "../../Form/TextArea";
import DateTimeField from "../../Form/DateTimeField";
import { useTranslation } from "react-i18next";
import NewModal from "../../shared/Modals/NewModal";
import SaveButton from "../../shared/Buttons/SaveButton";
import Post from "@serie3/mymediaplanner/Modules/Social/Entity/Post";
import { useState, useEffect } from "react";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import ComposePostTab from "./ComposPostTab";
import SchedulePostTab from "./SchedulePostTab";

const PostFormView = ({
  showEdit,
  setShowEdit,
  editData,
  dataList,
  setDataList,
  setEditData,
  trigger,
  setTrigger,
}) => {
  const editPostForm = PostForm.createPostForm(
    dataList,
    setDataList,
    setShowEdit
  );

  const { t } = useTranslation();
  const [triggerRerenderProfile, setTriggerRerenderProfile] = useState(-1);
  const [postPicture, setPostPicture] = useState(null);
  const formik = useFormik(editPostForm);
  
  useEffect(() => {
    const getImage = async () => {
      const response = await crudApi.getBinary(
        SocialEndpoints.posts.postImage + editData.id
      );
      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);
        setPostPicture(url);
      }
    };

    if (editData && editData.file_name) {
      getImage();
    }
  }, [editData, triggerRerenderProfile]);

  const Save = () => {
    return (
      <>
        <SaveButton
          submitFunction={(e) => {
            formik.handleSubmit(e);
            e.preventDefault();
          }}
        />
      </>
    );
  };

  return (
    <>
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        title={formik.values.title}
        ExtraButton={Save}
        fullscreen={true}
        onShow={() => {
          editData
            ? formik.setValues(PostForm.setEditData(editData))
            : formik.setValues(Post);
        }}
        onExit={() => {
          let triggervalue = trigger * -1;
          setTrigger(triggervalue);
          formik.resetForm({ values: { Post } });
          setPostPicture(null);
        }}
      >
        <Tabs
          defaultActiveKey="compose"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="compose" title={t("Backend.Post.Tabs.Compose")}>
            <ComposePostTab
              formik={formik}
              editData={editData}
              setEditData={setEditData}
              triggerRerenderProfile={trigger}
              setTriggerRerenderProfile={setTriggerRerenderProfile}
              setPostPicture={setPostPicture}
              postPicture={postPicture}
            />
          </Tab>
          <Tab eventKey="schedule" title={t("Backend.Post.Tabs.Schedule")}>
            <SchedulePostTab post={editData}/>
          </Tab>
        </Tabs>
      </NewModal>
    </>
  );
};

export default PostFormView;
