import { Card, Col, Row } from "react-bootstrap";
import TextField from "../../Form/TextField";
import TextArea from "../../Form/TextArea";
import { useTranslation } from "react-i18next";
import NewModal from "../../shared/Modals/NewModal";
import FileDropzone from "../../shared/Modals/FileDropzone";
import { useState } from "react";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import EmojiPicker from "../../shared/EmojiPicker";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Dropdown } from "react-bootstrap";
import EllipsisVerticalToggle from "../../shared/Dropdown/EllipsisVerticalToggle";
import crudApi from "@serie3/common/api/crudApi";
import LoadingModal from "../../shared/Modals/LoadingModal";
import { faMicrochip, faImage } from "@fortawesome/free-solid-svg-icons";
import SelectField from "../../Form/SelectField";

const ComposePostTab = ({
  formik,
  editData,
  setEditData,
  triggerRerenderProfile,
  setTriggerRerenderProfile,
  postPicture,
}) => {
  const { t } = useTranslation();

  const [showFileUpload, setShowFileUpload] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const setData = (data) => {
    setEditData(data[0]);
    let renderValue = triggerRerenderProfile * -1;
    setTriggerRerenderProfile(renderValue);
  };

  let isEdit = editData ? true : false;
  let additionalData = editData ? { id: editData.id } : {};

  // Function to handle text change and capture cursor position
  const handleTextChange = (e) => {
    const { selectionStart } = e.target;
    setCursorPosition(selectionStart);
    formik.setFieldValue("text", e.target.value);
  };

  const handleAddEmoji = (emoji) => {
    const text = formik.values.text || "";
    const newText =
      text.substring(0, cursorPosition) +
      emoji.native +
      text.substring(cursorPosition);

    formik.setFieldValue("text", newText);
    setCursorPosition(cursorPosition + emoji.native.length);
  };

  const finishPost = async (postId) => {
    setShowLoadingModal(true);
    const response = await crudApi.justGet(
      SocialEndpoints.posts.ai.finishPost + postId
    );
    if (response.ok) {
      formik.setFieldValue("text", response.data);
      setShowLoadingModal(false);
    }
  };

  const LabelButton = ({ charCount = 0 }) => {
    return (
      <Row>
        <Col className="d-flex align-items-center ">
          <strong>
            {t("Backend.Post.Text")} ({charCount})
          </strong>
        </Col>
        <Col className="text-end">
          <EmojiPicker handleAddEmoji={handleAddEmoji} />

          <Dropdown style={{ float: "right" }}>
            <EllipsisVerticalToggle icon={faMicrochip} />
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  finishPost(editData.id);
                }}
              >
                <i className="pli-pen-5 fs-5 me-2" />
                {t("Backend.Post.FinishPost")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown style={{ float: "right" }}>
            <EllipsisVerticalToggle icon={faImage} />
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setShowFileUpload(true)}>
                <i className="pli-pen-5 fs-5 me-2" />
                {postPicture
                  ? t("Backend.Post.ChangeImage")
                  : t("Backend.Post.UploadImage")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <NewModal show={showFileUpload} setShow={setShowFileUpload}>
        <FileDropzone
          uploadUrl={SocialEndpoints.posts.edit}
          setName={false}
          setData={setData}
          edit={isEdit}
          additionalData={additionalData}
        />
      </NewModal>
      <LoadingModal
        showSpinner={showLoadingModal}
        setShowSpinner={setShowLoadingModal}
        text={t("Backend.Post.AIworking")}
      />
      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <TextField
                    formik={formik}
                    label={t("Backend.Post.Title")}
                    valueName={"title"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectField
                    formik={formik}
                    label={t("Backend.Post.ContentType.Title")}
                    valueName={"content_type"}
                    style={{ marginBottom: "10px" }}
                    value={formik.values.content_type}
                  >
                    <option value="Personal">
                      {t("Backend.Post.ContentType.Personal")}
                    </option>
                    <option value="Business">
                      {t("Backend.Post.ContentType.Business")}
                    </option>
                    <option value="Technical">
                      {t("Backend.Post.ContentType.Technical")}
                    </option>
                    <option value="Educational">
                      {t("Backend.Post.ContentType.Educational")}
                    </option>
                    <option value="News">
                      {t("Backend.Post.ContentType.News")}
                    </option>
                    <option value="Creative">
                      {t("Backend.Post.ContentType.Creative")}
                    </option>
                    <option value="Social">
                      {t("Backend.Post.ContentType.Social")}
                    </option>
                    <option value="Entertainment">
                      {t("Backend.Post.ContentType.Entertainment")}
                    </option>
                    <option value="Marketing">
                      {t("Backend.Post.ContentType.Marketing")}
                    </option>
                    <option value="Instructional">
                      {t("Backend.Post.ContentType.Instructional")}
                    </option>
                  </SelectField>
                </Col>
                <Col>
                  <SelectField
                    formik={formik}
                    label={t("Backend.Post.PostType.Title")}
                    valueName={"post_type"}
                    style={{ marginBottom: "10px" }}
                    value={formik.values.post_type}
                  >
                    <option value="Text">
                      {t("Backend.Post.PostType.Text")}
                    </option>
                    <option value="TextImage">
                      {t("Backend.Post.PostType.TextImage")}
                    </option>
                    <option value="Carousel">
                      {t("Backend.Post.PostType.Carousel")}
                    </option>
                    <option value="Video">
                      {t("Backend.Post.PostType.Video")}
                    </option>
                    <option value="Shortform">
                      {t("Backend.Post.PostType.Shortform")}{" "}
                    </option>
                    <option value="Stories">
                      {t("Backend.Post.PostType.Stories")}
                    </option>
                    <option value="Poll">
                      {t("Backend.Post.PostType.Poll")}
                    </option>
                    <option value="LinkPost">
                      {t("Backend.Post.PostType.LinkPost")}
                    </option>
                    <option value="Infographic">
                      {t("Backend.Post.PostType.Infographic")}
                    </option>
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <TextArea
                    formik={formik}
                    label=" "
                    valueName={"text"}
                    style={{ marginBottom: "10px" }}
                    rows={25}
                    onClick={(e) => handleTextChange(e)}
                    LabelButton={() => (
                      <LabelButton
                        charCount={
                          formik.values.text && formik.values.text.length
                        }
                      />
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Tabs
                    defaultActiveKey="compose"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab
                      eventKey="compose"
                      title={t("Backend.Post.Tabs.Preview.LinkedIn")}
                    >
                      {formik.values.text &&
                        formik.values.text.length > 3000 && (
                          <Row className="mb-3">
                            <Col
                              style={{
                                color: "red",
                                fontSize: "small",
                              }}
                            >
                              {t("Backend.Post.Linkedin.LengthWarning")}
                            </Col>
                          </Row>
                        )}
                      <Row>
                        <Col>
                          <div
                            style={{
                              backgroundColor: "#f3f3f3",
                              minHeight: "500px",
                              padding: "10px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                formik.values.text &&
                                formik.values.text.replace(/\n/g, "<br />"),
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ maxWidth: "100%" }}>
                          {postPicture && (
                            <div className="timeline-album">
                              <img
                                src={postPicture}
                                alt="postimage"
                                style={{ maxWidth: "100%" }}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ComposePostTab;
